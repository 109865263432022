@keyframes blink-animation {
    to {
        visibility: hidden;
    }
}

.internet-connection-status {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 1000;

    &__no-internet-icon {
        width: 15vw;
        height: 40vh;
        display: block;

        animation: blink-animation 1s steps(4, start) infinite;
    }
}
