.button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #59abe3;
    color: #000000;
    padding: 0.3em;
    width: fit-content;
    height: min-content;
    flex-shrink: 0;

    &_disabled {
        opacity: 0.5 !important;
        color: #000000 !important;
        background-color: #59abe3 !important;
    }

    &_active {
        cursor: default !important;
    }

    &_danger {
        background-color: #ff1000 !important;
    }

    &_unobtrusive {
        border-color: unset;
        background-color: #555555 !important;
        color: #c7c7c7 !important;
        border-radius: 4px;

        &:hover {
            border-color: #000000;
        }
    }

    &_yan {
      color: #fb3f1d;
      background-color: #fff;
      border-color: #dedcdc;

      &:hover {
        color: #fb3f1d;
        background-color: #f1efef;
        border-color: #dedcdc;
      }

      &:active {
        color: #fb3f1d;
        background-color: #f1efef;
        border-color: #dedcdc;
        box-shadow: unset;
      }

      &:focus {
        color: #fb3f1d;
        background-color: #f1efef;
        border-color: #dedcdc;
        box-shadow: unset;
      }

      &:active&:focus {
        box-shadow: unset;
      }
    }
}

.spinner-border {
    width: 1rem;
    height: 1rem;
    padding: 0 !important;
}

.button_unobtrusive .btn-primary:hover {
    border-color: unset;
}
