:root {
    --toolbarHeight: 6vh;
    --toolbarVerticalPadding: 24px;
    --toolbarBorderBottom: 1px;
    --controllerFullHeight: calc(100vh - var(--toolbarHeight) - var(--toolbarVerticalPadding) * 2 - var(--toolbarBorderBottom));
    --defaultFontSize: 16px;
    --dangerColor: #dc0d1f;
    --tableHeaderBorderTop: 1px;
    --tableHeaderBorderBottom: 2px;
    --tableHeaderZIndex: 11;
}

@media(max-width: 1400px) {
  :root {
      --defaultFontSize: 14px;
  }
}

.colors-danger {
    color: var(--dangerColor);
}

html {
    box-sizing: border-box;
    margin: 0;
}

*, *:before, *:after {
    box-sizing: inherit;
}

body {
    margin: 0 !important;
    background-color: #f6f5f3 !important;
    font-size: var(--defaultFontSize) !important;
}

a:hover {
    text-decoration: unset;
    color: #2574a9;
}

::-webkit-scrollbar-track {
    border-radius: 6px;
    background-color: transparent !important;
}

::-webkit-scrollbar {
    width: 8px;
    background-color: #3498db !important;
}

::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: #89c4f4 !important;
}

.markdown-body {
    box-sizing: border-box;
    min-width: 200px;
    max-width: 100%;
    width: max-content;
}

textarea.bp3-input {
    max-width: 150px;
}

label {
    display: unset;
    margin-bottom: 0;
}

/* ===> table */
table {
    box-sizing: unset;
    border-collapse: separate;
    border-spacing: 0;
}

thead {
  position: sticky;
  z-index: var(--tableHeaderZIndex);
  top: 0;
}
thead:after,
thead:before {
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
  border: 0 solid #000000;
}
thead:before {
  top: 0;
  border-top-width: var(--tableHeaderBorderTop);
}
thead:after {
  bottom: 0;
  border-bottom-width: var(--tableHeaderBorderBottom);
}

th {
    background-color: #ffffff;
    text-align: center;
    vertical-align: middle !important;
    padding: 0.5vh 0.5vw;
    height: 5vh;
    border: 1px solid #000000;
    border-top: none;
}

td {
    text-align: center;
    border: 1px solid #000000;
    border-top-width: 0;
    max-width: 20vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 2px;
}
td.td-unlimited-width {
  max-width: unset;
}
/* <=== table */

/* hide input number arrows */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
}

/* make modal z-index less than alert */
.modal-backdrop {
  z-index: 998;
}

.modal {
    z-index: 999;
}


span, label {
  flex-shrink: 0;
}

.warning-text {
    color: #faad14;
}

.clickable {
    cursor: pointer;
}

.red-text {
  color: #e5193a;
}

.green-text {
  color: #00cc00;
}

.uppercase-text {
  text-transform: uppercase;
}

.flex {
  display: flex;
}

h1, h2, h3, h4 {
  margin-bottom: 0;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.3rem;
}

h4 {
  font-size: 1.1rem;
}

:focus {
  outline: none;
}

.fullWidth {
  width: 100%;
}
