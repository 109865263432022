.dropdown {
    text-align-last:center;
    height: max-content;
    width: 100%;

    &__select {
        margin-left: 10px;
        width: max-content;
    }

    &_read-only {
        border: none;
        background: transparent;
        /* for Firefox */
        -moz-appearance: none;
        /* for Chrome */
        -webkit-appearance: none;
    }

    &_empty-value&_required {
        color: red;
    }

    &__empty-option {
        text-align: center;
    }

    option {
        color: black;
    }
}
