.toolbar {
    height: var(--toolbarHeight);
    background-color: #ffffff;
    display: flex;
    align-items: center;
    padding: var(--toolbarVerticalPadding) 0.6em;
    justify-content: space-between;
    user-select: none;
    border-bottom: var(--toolbarBorderBottom) solid #e7e6e4;
    overflow-x: auto;
    overflow-y: hidden;

    &_is-mobile {
      overflow-x: hidden;
    }

    &__project-name {
        display: flex;
        align-items: center;
        gap: 10px;
        font-weight: bold;
        font-size: large;
        color: #000;
        white-space: nowrap;
        line-height: 0.8;
    }

    &__version-container {
        display: flex;
        flex-direction: column;
        font-size: 1.5rem;
    }

    &__version {
        color: #8a8888;
        font-size: xx-small;
        align-self: end;
        margin-top: -0.3em;
    }

    &__user-name {
        font-size: large;
        font-weight: bold;
        margin-left: 4vh;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex-shrink: unset;

        &_is-mobile {
            margin-left: 8px;
        }
    }

    &__actions {
        display: flex;
        align-items: center;
    }

    &__action {
        margin-left: 10px;
        flex-shrink: 0;
    }

    &__icons {
      display: inline-block;
      background: no-repeat 50% 50%;
      background-size: contain;

      &_skd-crm {
        background-image: url('public/icons/skd-crm.svg');
        width: 4.4em;
        height: 1.1em;
        margin-right: -0.1em;
      }

      &_skd-crm-ny {
        background-image: url('public/icons/skd-crm-ny.svg');
        width: 4.4em;
        height: 1.1em;
        margin-right: -0.1em;
      }
    }
}
