.check-translate-page {
    position: fixed;
    bottom: 10px;
    left: 10px;
    z-index: 1000;

    border-radius: 5px;
    color: #fff;
    padding: 10px 14px 10px 10px;
    box-shadow: 0 1px 5px #999;
    background-color: #8a9bab;

    &__cancel {
      display: inline-block;
      background: no-repeat 50% 50%;
      background-size: contain;

      position: absolute;
      top: 2px;
      right: 2px;
      background-image: url('public/icons/cancel.svg');
      width: 8px;
      height: 8px;
      cursor: pointer;
  }
}
