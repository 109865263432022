.not-find {
  font-size: larger;
  cursor: pointer;
  background-color: #f6f5f3;
  display: flex;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
  text-align: center;
}
