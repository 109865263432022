.help {
  padding: 25px;
  overflow-y: scroll;

  h2, h3 {
      cursor: pointer;
      user-select: none;

      &:before {
        content: '';
        display: inline-block;
        background: no-repeat 50% 50%;
        background-size: contain;
        background-image: url('i/paragraph.svg');
        width: 18px;
        height: 18px;
      }
  }

  h2 {
      text-align: center;
      font-weight: bold;
  }

  h3 {
      margin-top: 5vh;
  }

  p {
      text-indent: 1em;
  }

  &__import-template {
      display: flex;
      justify-content: center;
      align-content: center;

      a {
          font-size: 3em;
          border: 2px #19b5fe dashed;
          width: fit-content;
      }
  }
}

@media screen and (min-width: 960px) {
  .help {
    margin-left: calc(100vw - 100%);
    margin-right: 0;
  }
}
