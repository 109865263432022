/* @gravity-ui */
.g-root {
    color: unset;
    background: unset;
    font-family: unset;
    font-size: unset;
    font-weight: unset;
    line-height: unset;
}

.g-root:not(.g-root_mobile):not(.g-root_native-scrollbar) {
    scrollbar-color: unset;
}

.g-text_ellipsis {
    width: 100%;
}
